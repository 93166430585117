<template>
    <Toast />
    <div class="col-12 lg:col-4 flex flex-row" style="width: 100vw;">
        <div v-if="isAdm" class="card height-100">
            <input type="file" ref="fileInput" style="display: none" @change="handleFileUpload"
                accept="application/pdf" />
            <Button icon="pi pi-download" @click="triggerFileInput" type="button" class="p-button-success mr-2"
                label="Upload Estatuto Social" />
        </div>

    </div>
    <iframe :src="pdfUrl" width="100%" height="100%" frameborder="0"></iframe>


</template>

<script lang="js">
import EstatutoSocial from "../../service/EstatutoSocial";

export default {
    name: 'EstatutoSocial',
    data() {
        return {
            database: [],
            pdfUrl: null,
            isAdm: false,
        };
    },

    created() {
        this.renderPdf();
        if (this.$store.state.auth.user.administrador) {
            this.isAdm = true;
        }
    },

    methods: {
        handleDownloadError() {
            this.$toast.add({
                severity: "error",
                detail: "Erro ao baixar o arquivo",
                life: 5000
            });
        },

        triggerFileInput() {
            this.$refs.fileInput.click();
        },

        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.convertPDFToBase64(file).then((base64) => {
                    this.uploadEstatutoSocialBucket(base64);
                });
            }
        },

        uploadEstatutoSocialBucket(base64) {
            EstatutoSocial.uploadEstatutoSocial(base64).finally(() => {
                this.renderPdf();
            }).catch(() => {
                this.$toast.add({
                    severity: "error",
                    detail: "Erro realizar o upload do arquivo",
                    life: 5000
                });
            });
        },

        getPDF() {
            EstatutoSocial.getEstatutoSocialBucket().then((response) => {
                this.convertBase64ToPDF(response.data);
            }).catch(() => {
                this.handleDownloadError();
            });
        },

        convertPDFToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const base64String = e.target.result.split(',')[1];
                    resolve(base64String);
                };

                reader.onerror = (error) => {
                    reject(error);
                };

                reader.readAsDataURL(file);
            });

        },

        getFileURL(response) {
            const byteCharacters = atob(response.data);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            return fileURL;
        },

        renderPdf() {
            EstatutoSocial.getEstatutoSocialBucket().then((response) => {
                const fileURL = this.getFileURL(response);
                this.pdfUrl = fileURL;
                return fileURL;
            }).catch(() => {
                this.handleDownloadError();
            });
        },

        convertBase64ToPDF(base64String) {
            EstatutoSocial.getEstatutoSocialBucket().then((response) => {
                const fileURL = this.getFileURL(base64String);
                this.pdfUrl = fileURL;

                const downloadLink = document.createElement('a');
                downloadLink.href = fileURL;
                downloadLink.download = 'estatuto_social.pdf';
                downloadLink.click();
            }).catch(() => {
                this.handleDownloadError();
            });

        },

    }
}

</script>