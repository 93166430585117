import axios from "axios";
import PortalCooperadoUtils from "../utilities/PortalCooperadoUtils";

const ESTATUTO_API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/estatuto-social`;

class EstatutoSocialService {
  getEstatutoSocialBucket() {
    return axios.get(ESTATUTO_API_BASE_URL + RESOURCE_V1 + "/pegar-estatuto");
  }

  uploadEstatutoSocial(base64) {
    return axios.post(ESTATUTO_API_BASE_URL + RESOURCE_V1 + "/update-estatuto", base64, {
      headers: {
        "Content-Type": "application/text",
      },
    });
  }
}

export default new EstatutoSocialService();
